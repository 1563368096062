<template>
  <div class="Box">
    <div class="banner_center">
      <div class="banner_safe">
            <div class="banner_word">
                <p>慧芯课程中心</p>
                <p>行业前沿专业课程，校企名师专家亲授</p>
            </div>
      </div>
    </div>
    <div class="center_main">
      <div class="header">
        <div class="header_left">
          <img src="~@/assets/Coursecenter_img/moni.png" alt="" />
        </div>
        <div class="header_right">
          <div class="header_right_word">模拟IC设计实践</div>
          <div class="header_right_word_two">通过本课程成熟完善的EDA工具体系课程，为希望了解模拟集成电路设计的学生讲授关于模拟集成电路设计的相关知识，内容包括从模拟电路的原理设计、仿真、版图设计、物理验证、寄生参数提取直至后仿真的整个流程。</div>
         <div class="header_right_div">
          <div class="div_img"><img src="~@/assets/Coursecenter_img/xiaoxiang.png" alt=""></div>
          <div class="div_right">
              <div class="div_word">讲师：余涵</div>
              <div class="div_word_two">国内资深EDA系统专家、华大九天大学计划与业务合作总监<br>
                  15年IC行业工作背景， 其中10年国产EDA工作背景</div>
          </div>
        </div>
        </div>
      </div>
      <div class="center_lists" v-show="isActive">
          <ul >
              <li  v-for="item in lists" :key="item.id">
                  <div class="li_img">
                      <img :src="item.icon" alt="">
                  </div>
                  <div class="li_word">
                    {{item.name}}
                  </div>
                  <div class="li_right">
                      <img :src="item.icon2" alt="">
                      <span class="li_right_icon_word">{{item.time}}</span>
                  </div>
                  <div class="play">
                      <span>播放</span>
                  </div>
              </li>
             
          </ul>
      </div>
      <div class="shrink">
          <div class="shrink_box" @click="toggle">
              <img :src="arrowsyurl" alt="">
              <span >{{word}}</span>
          </div>
      </div>
    </div>
  </div>
</template>
styl
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.Box {
  width: 100%;
  margin: 0 auto;
  min-width: 1200px;
  max-width: 1920px;
  background: #f1f3f7;  
  height: 100%;
  overflow: hidden;
}

.banner_center {
  width: auto;
  height: 340px;
  background: url("~@/assets/Coursecenter_img/coour_bj.png") center center
    no-repeat;
  background-size: cover;
}
.center_main {
  width: 1200px;
  margin: 40px auto;
  background: #FFFFFF
}
.header {
  width: 1147px;
  height: 270px;
  margin: 20px auto;
  display: flex;
 
  .header_left {
    width: 350px;
    height: 270px;
    display: flex;
    align-items: center;
    margin-right: 19px;
  }
  .header_right {
    width: 778px;
    height: 270px;
    .header_right_word {
      
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      margin-top: 30px;
    }
    .header_right_word_two{
height: 44px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #666666;
line-height: 20px;
margin-top: 6px;
    }
    .header_right_div{
        height: 80px;
        margin-top: 30px;
        display: flex;
        .div_img{
             width: 64px;
             height: 64px;
             overflow: hidden;
             border-radius: 50%;
             img{
                 width: 64px;
                 height: 80px;
             }
        }
        .div_right{
            width: 385px;
            margin-left: 10px;
            
        }
    }
  }
  .div_word{
height: 22px;
font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
font-weight: 500;
color: #333333;
line-height: 22px;
            }
            .div_word_two{
height: 61px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #666666;
line-height: 20px;
margin-top: 7px;
            }
}
.center_lists{
    height: 605px;
    width: 1200px;
        ul{
            width: 1147px;
            margin: 0 auto;
     border-top: 1px solid #F3F3F3;
            
            
            li{
                height: 60px;
                display:flex;
               
                    align-items: center;
                .li_img{
                    display: flex;
                }
                .li_word{
                    width: 224px;
height: 22px;
font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
font-weight: 500;
color: #333333;
line-height: 22px;
margin-left: 10px;
                }
                .li_right{
                    width: 55px;
                    height: 20px ;
                    margin-left: 296px;
                    display: flex;
                    align-items: center;
                    .li_right_icon_word{
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #666666;
line-height: 20px;
                    }
                }
                .play{
                    width: 90px;
height: 30px;
background: #0700FF;
border-radius: 20px;
text-align: center;
line-height: 30px;
margin-left: 406px;
display: none;
                span{
height: 20px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #FFFFFF;
line-height: 20px;
                }
                }
            }
            li:hover{
               background: #F1F3F7;
               cursor: pointer;
               .play{
                   /* display: block; */
               }
            }
        }
}
.shrink{
    width: 1200px;
    height: 62px;
    border-top:1px solid #F3F3F3;
    box-shadow: 0px 1px 0px 0px #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    .shrink_box{
        width: 71px;
        height: 25px;
        cursor: pointer;
        img{
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
        span{
height: 25px;
font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC,PingFang-SC-Regular;
font-weight: 400;
color: #231DFF;
line-height: 25px;
letter-spacing: 5px;
margin-left: 8px;
        }
        }
}
.banner_safe{
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;
}
.banner_word{
  height: 136px;
  width: 590px;
  margin-top: 95px;
  margin-left: 4%;
}
.banner_word p:first-child{
height: 87px;
font-size: 62px;
font-family: PingFangSC-Semibold, PingFang SC,PingFang-SC-Regular;
font-weight: 600;
color: #000000;
line-height: 87px;
}
.banner_word p:last-child{
height: 33px;
font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC,PingFang-SC-Regular;
font-weight: 500;
color: #000000;
line-height: 33px;
margin-top: 16px;
opacity: 0.7;
}
</style>
<script>
import '@/assets/font/PingFang-SC-Regular.css'
export default {
  
    data() {
        return {
            lists:[
                {  id:0, icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时1：MOS管的伏安特性曲线',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'32:37'},
                { id:1,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时2：CMOS共源放大器',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'30:47'},
                {  id:2,  icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时3：CMOS源随器',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'32:20'},
                { id:3,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时4：差动放大器',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'29:55'},
                {  id:4,  icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时5：电流镜',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'30:45'},
                {  id:5,  icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时6：五管OTA',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'31:27'},
                { id:6,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时7：频率响应',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'30:33'},
                { id:7,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时8：负反馈',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'29:58'},
                {id:8,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时9：AB类放大器',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'32:49'},
                { id:9,   icon:require('@/assets/Coursecenter_img/center_icon1.png'),name:'课时10：MOS管的进阶特性',icon2:require('@/assets/Coursecenter_img/center_icon2.png'),time:'28:53'}

            ],
            word:'收起',
            isActive:true,
            arrowsyurl:require('@/assets/Coursecenter_img/zhankai.png')
        }
    },
    methods: {
        toggle(){
            if(this.word==='收起'){
            this.word ='展开'
            this.isActive=false
            this.arrowsyurl=require('@/assets/Coursecenter_img/zhankai.png')

            }
            else{
            this.word ='收起'
            this.isActive=true
            this.arrowsyurl=require('@/assets/Coursecenter_img/shouqi.png')

            }
        }
    },
};
</script>